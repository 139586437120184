<template>
  <div>
    <AilmentFilter
      v-if="$can('filter', 'ailments')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Personal pathological history" iconTable="fas fa-disease" />
      <div class="m-1">
        <AilmentEdit
          v-if="isEditAilmentSidebarActive"
          :is-edit-ailment-sidebar-active.sync="isEditAilmentSidebarActive"
          :itemEdit="itemEdit"
          @editAilmentSuccess="editAilmentSuccess"
        />
        <AilmentAdd
          :is-add-new-ailment-sidebar-active.sync="isAddNewAilmentSidebarActive"
          @createAilment="createAilment"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25"
            />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                v-if="$can('store', 'ailments')"
                variant="primary"
                @click="isAddNewAilmentSidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Ailment</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="$can('update', 'ailments')"
            variant="outline-primary"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Edit'"
            @click="editAilment(data.item)"
          >
            <i class="fas fa-pen icon-nm"></i>
          </b-button>

          <b-button
            v-if="$can('destroy', 'ailments')"
            variant="outline-danger"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Delete'"
            @click="deleteAilment(data.item)"
          >
            <i class="fas fa-trash-alt icon-nm"></i>
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import axiosAC from "@/core/services/api/admin/consults/ailments";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import Swal from "sweetalert2";

import AilmentEdit from "./AilmentEdit.vue";
import AilmentAdd from "./AilmentAdd.vue";
import AilmentFilter from "./AilmentFilter.vue";
import { ref } from "@vue/composition-api";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    AilmentEdit,
    AilmentAdd,
    AilmentFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        "id",
        // A column that needs custom formatting
        { key: "name", label: "Name", sortable: true },
        // A regular column
        // A regular column
        { key: "status", label: "Status" },
        // A virtual column made up from two fields
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      isEditAilmentSidebarActive: false,
      isAddNewAilmentSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map((i) => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      ailmentFilter: null,
    };
  },
  mounted() {
    this.getAilment();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getAilment();
      } else {
        this.searchFilter(this.ailmentFilter);
      }
    },
  },
  methods: {
    getAilment() {
      axiosAC
        .ailmentList(this.perPage)
        .then(({ data, current_page, total, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    editAilment(item) {
      this.isEditAilmentSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editAilmentSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Ailment updated successfully");
      } else {
        this.$refs.toast.error("Error updating type ailment");
      }
      this.isEditAilmentSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createAilment({ status }) {
      if (status) {
        this.$refs.toast.success("Ailment type added successfully");
      } else {
        this.$refs.toast.danger("Ailment type not added");
      }
      this.isAddNewAilmentSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    deleteAilment(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosAC.ailmentDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "Ailment type has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosAC
          .ailmentPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosAC
          .ailmentFilterPagination(this.perPage, page, this.ailmentFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.ailmentFilter = value;
        axiosAC
          .ailmentFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getAilment();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style></style>
